<template id="">
  <div v-if="company_detail">
    <h3>インテリジェントヘルスケア ストレスチェックApp</h3>
    <div
      v-show="
        !$store.state.common.sIsLoading && !$store.state.common.sDataLoading
      "
    >
      <div class="tag-group">
        <ul>
          <li v-bind:class="{ active: true }">企業情報</li>
          <li @click="$router.replace('/intelligent-employee_list/' + com_id)">
            従業員一覧
          </li>
          <li
            @click="
              $router.replace('/intelligent-stress_check_history/' + com_id)
            "
          >
            ストレスチェック受診歴
          </li>
        </ul>
      </div>
      <table class="kigyo">
        <tr>
          <th>企業名<sup v-if="editable">※</sup> ：</th>
          <td>
            <input
              type="text"
              name=""
              v-model="company_detail.com_name"
              :disabled="!editable"
            />
          </td>
        </tr>
        <tr>
          <th>企業名(フリガナ)<sup v-if="editable">※</sup>：</th>
          <td>
            <input
              type="text"
              name=""
              v-model="company_detail.com_kana"
              :disabled="!editable"
            />
          </td>
        </tr>
        <tr>
          <th>代表者氏名：</th>
          <td>
            <input
              type="text"
              name=""
              v-model="company_detail.representative_name"
              :disabled="!editable"
            />
          </td>
        </tr>
        <tr>
          <th>郵便番号：</th>
          <td>
            <input
              type="text"
              name=""
              v-model="company_detail.com_zip"
              :disabled="!editable"
            />
          </td>
        </tr>
        <tr>
          <th>住所：</th>
          <td>
            <input
              type="text"
              name=""
              v-model="company_detail.com_address"
              :disabled="!editable"
            />
          </td>
        </tr>
        <tr>
          <th>電話番号：</th>
          <td>
            <input
              type="text"
              name=""
              v-model="company_detail.com_tel"
              :disabled="!editable"
            />
          </td>
        </tr>
        <tr>
          <th>担当部署<sup v-if="editable">※</sup>：</th>
          <td>
            <input
              type="text"
              name=""
              v-model="company_detail.tantou_busyo"
              :disabled="!editable"
            />
          </td>
        </tr>
        <tr>
          <th>担当者氏名<sup v-if="editable">※</sup>：</th>
          <td>
            <input
              type="text"
              name=""
              v-model="company_detail.tantou_name"
              :disabled="!editable"
            />
          </td>
        </tr>
        <tr>
          <th>担当者email<sup v-if="editable">※</sup>：</th>
          <td>
            <input
              type="text"
              name=""
              v-model="company_detail.email"
              :disabled="!editable"
            />
          </td>
        </tr>
      </table>
      <div style="margin-top: 2%">
        <button
          type="button"
          name="button"
          class="uk-button uk-button-secondary uk-width-1-2"
          v-if="!editable"
          @click="editable = true"
        >
          編集
        </button>
        <button
          type="button"
          name="button"
          class="uk-button uk-button-secondary uk-width-1-2"
          v-else
          :disabled="!activeSubmit"
          @click="edit_info_company"
        >
          {{ button_label }}
        </button>
        <br />
        <button
          name="button"
          class="uk-button uk-button-secondary uk-width-1-2"
          v-if="editable"
          @click="showModal()"
        >
          削除
        </button>
        <div v-if="modal">
          <div id="modal-content" class="modal">
            <p id="modal-message" class="modal__message">
              {{ company_detail.com_name }}を削除してもよろしいですか？
            </p>
            <p>削除を確認するには、削除 と入力してください</p>
            <input
              type="text"
              v-model="delete_confirm_text"
              placeholder="削除"
            />
            <div>
              <button
                class="uk-button uk-button-default"
                @click="delete_cancel()"
              >
                キャンセル
              </button>
              <button
                class="uk-button uk-button-danger"
                @click="delete_company_info()"
                :disabled="delete_confirm_text != '削除'"
              >
                削除
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      status: null,
      com_id: null,
      company_detail: null,
      pre_data: {},
      editable: false,
      do_something: true,
      button_label: "戻る",
      delete_confirm: false,
      delete_confirm_text: "",
      modal: false,
    };
  },
  mounted() {
    this.$store.state.common.sIsLoading = false;
    document.title = "企業情報";
  },
  created() {
    this.$store.state.common.sIsLoading = true;
    this.$store.state.common.sDataLoading = true;
    this.status = this.$store.state.login_status_i;
    this.checkLogined();
    if (this.status) {
      this.get_company_detail();
    }
  },
  computed: {
    activeSubmit() {
      if (
        this.company_detail.com_name === this.pre_data.com_name &&
        this.company_detail.com_kana === this.pre_data.com_kana &&
        this.company_detail.representative_name ===
          this.pre_data.representative_name &&
        this.company_detail.com_zip === this.pre_data.com_zip &&
        this.company_detail.com_address === this.pre_data.com_address &&
        this.company_detail.com_tel === this.pre_data.com_tel &&
        this.company_detail.tantou_busyo === this.pre_data.tantou_busyo &&
        this.company_detail.tantou_name === this.pre_data.tantou_name &&
        this.company_detail.email === this.pre_data.email
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    delete_cancel() {
      this.delete_confirm_text = "";
      this.modal = false;
    },
    showModal() {
      // モーダル表示する際の処理が必要ならここに書く
      this.modal = true;
    },
    delete_company_info() {
      if (!this.do_something) return;
      this.do_something = false;
      this.delete_confirm_text = "";
      this.$axios
        .post("/intelligent/delete_info_company", {
          com_id: this.com_id,
        })
        .then((response) => {
          this.$router.replace("/intelligent-menu");
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
      this.modal = false;
    },
    edit_info_company() {
      if (!this.do_something) return;
      this.do_something = false;
      if (
        this.company_detail.com_name == "" ||
        this.company_detail.com_kana == "" ||
        this.company_detail.tantou_busyo == "" ||
        this.company_detail.tantou_name == "" ||
        this.company_detail.tantou_email == ""
      ) {
        alert("必須項目を入力してください");
        return;
      }
      this.$axios
        .post("/intelligent/edit_info_company", {
          com_id: this.com_id,
          company_detail: this.company_detail,
        })
        .then((response) => {
          if (response.data.flg) {
            this.do_something = true;
            this.get_company_detail();
            alert("保存しました");
          } else {
            console.log(
              "エラーが発生しました。\nお手数ですがもう一度やり直してください。"
            );
            this.do_something = true;
          }
          this.editable = false;
        })
        .catch((error) => {
          console.log(error);
          this.do_something = true;
        });
    },
    get_company_detail() {
      if (!this.do_something) return;
      this.do_something = false;
      this.com_id = this.$route.path.replace("/intelligent-company_info/", "");
      console.log(this.com_id);
      this.$axios
        .post("/intelligent/get_company_detail", {
          com_id: this.com_id,
        })
        .then((response) => {
          console.log(response);
          if (response.data.flg) {
            this.company_detail = response.data.company_details;
            this.pre_data = {
              ...this.company_detail,
            };
          }
          this.do_something = true;
          this.$store.state.common.sDataLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.do_something = true;
          this.$store.state.common.sDataLoading = false;
        });
    },
    checkLogined() {
      if (!this.status) {
        this.$router.push("/intelligent-login");
      }
    },
  },
  watch: {
    company_detail(newVal) {
      //eslint-disable-line
      console.log(newVal);
      this.button_label = "更新";
    },
  },
};
</script>

<style scoped>
.modal {
  padding: 10px 20px;
  border: 2px solid #00a2ff;
  background: #ffffff;
  z-index: 2;
  display: block;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}

.modal__message {
  margin-top: 10px;
}

#modal-overlay {
  z-index: 1;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
  background-color: rgba(0, 0, 0, 0.75);
}
table {
  width: 40%;
  margin-left: 30%;
}

table tr th {
  text-align: right;
  background: black;
  color: white;
  width: 15vw;
}

table tr td {
  padding-left: 1em;
  text-align: left;
  background: gainsboro;
}

input {
  font-size: 20px;
  padding: 0.2em 0.3em;
  border-radius: 3px;
  border: none;
  background: gainsboro;
}

button[type="button"] {
  font-size: 18px;
  padding: 0.2em 1em;
  background: #0366d6;
  color: #fff;
}

button[type="button"][disabled] {
  opacity: 0.4;
}
.uk-button-danger[disabled] {
  opacity: 0.8;
}
.uk-button-secondary {
  font-size: 18px;
  padding: 0.2em 1em;
  background: #000000;
  color: #fff;
}
div {
  display: inline-block;
  width: 100%;
}

#data-area {
  max-height: 80vh;
  overflow-y: auto;
}

.tag-group {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  zoom: 1;
  /* Fix for IE7 */
  *display: inline;
  /* Fix for IE7 */
}

ul {
  list-style: none;
  text-align: center;
}

.tab-group li {
  border: 1px solid #428bca;
  border-radius: 4px;
  position: relative;
  float: left;
  padding: 10px 15px 10px 15px;
}

.tab-group li:hover {
  background-color: #d4f0fd;
  cursor: pointer;
}

.active {
  background-color: #428bca;
  color: #fff;
}

.tab-group li + li {
  margin-left: -1px;
}

.tab-group > li:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.tab-group > li:first-child {
  margin-left: 0;
}

.tab-group > li:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.tab-group > li:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
table {
  text-align: center;
  width: 100%;
}
table tbody tr:hover {
  background: #d4f0fd;
  cursor: pointer;
}
</style>
